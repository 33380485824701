import "./KurzListek.css"
import { useState,useEffect } from "react"

const KurzListek = () => {

    const [poleKurzu,setPoleKurzu]=useState([])
    // const [datum,setDatum]=useState("2024-01-12")


    const nastavDatum = ()=> {
        const datum=new Date
        // datum.getDate()
        const rok=datum.getFullYear()
        let mesic=datum.getMonth()+1
        if (mesic<10) {mesic="0"+mesic}
        let den = datum.getDate()
        if (den<10) {den="0"+den}
         console.log(datum,rok,mesic,den);
         console.log("rok="+rok);
         console.log("mesic="+mesic);
         console.log("den="+den);
         const datumString = rok+"-"+mesic+"-"+den
         console.log(datumString);
        //  setDatum(datumString)
        const kalendar = document.querySelector("#vybDatum")
        kalendar.defaultValue=datumString
    }
    
     const[poleMen,setPoleMen] = useState(["AUD","BGN","CAD","CHF","DKK","EUR","GBP","HKD","HUF","JPY","NOK","NZD","PLN","RON","SEK","TND","TRY","USD","ZAR"])

     
    
   
    
    const getKurzy = async (cisloBanky,zadanyKurz,cisloKurzu) => {
        nastavDatum()
        FpoNastaveniDatumu(0) //meni barvu rlacitka
        const pozadovanyDatum=document.querySelector("#vybDatum").value

        //https://data.kurzy.cz/json/meny/b[2]den[20201231].json
       const result=pozadovanyDatum.replace(/-/g,"")
       console.log("Požadovaný datum="+result);
        console.log("zadanyKurz ve funkci getKurzy="+zadanyKurz);

       const adresa="https://data.kurzy.cz/json/meny/b["+cisloBanky+"]den["+result+"].json"
        console.log("cislo banky = "+cisloBanky)
        

    const urlkurzy= adresa //"https://data.kurzy.cz/json/meny/b[2].json"  
    const odpoved =await  fetch(urlkurzy)
    const dataKurzy= await odpoved.json()
    const jenomKurzy = (dataKurzy["kurzy"]);
    console.log("JenomKurzy=")
    console.log(jenomKurzy);

    const poleMen=Object.keys(jenomKurzy)

    const jeden = [zadanyKurz]

        // if (zadanyKurz!="VŠE") {setPoleMen(jeden)}

    console.log("PoleMen=========================")
    console.log(poleMen);
            console.log("*************************");

     if (zadanyKurz=="VŠE")  {setPoleMen(poleMen)}

    for (const key in jenomKurzy) {
        const value = jenomKurzy[key]
         
        // console.log(`${key} a ${value}`) 
      }
let poleHodnot=[]
      Object.keys(jenomKurzy).forEach(key => {
        const hod = jenomKurzy[key]
        // console.log(`${key} ahoj ${hod}`)
        // console.log("Výpis hodnot:");
        // console.log(hod);
        poleHodnot.push(hod)

      })

      console.log("Pole hodnot = ");
      console.log(poleHodnot);




//    const kurzZkratka = Object.keys(jenomKurzy)
//     console.log(kurzZkratka);
//     let i=0
//     let poleMen=[]
//     kurzZkratka.forEach((element) => {
//         poleMen[i]=element
//         // console.log(poleMen[i]);
//         i++
//     });
//     console.log(poleMen);
        
    
   
    
    let pole=[]
      console.log("Vypis nuloveho pole")
      console.log(pole);
      console.log("------------------");

    for (let i=0;i<poleMen.length;i++) {
        const jedenKurz=(jenomKurzy[poleMen[i]])
        pole.push(jedenKurz)
        
    } 
    console.log("vypis 1")
    console.log(pole)
    console.log("Cislo kurzu =="+cisloKurzu);
    
    if (cisloKurzu!=0) {console.log(pole[cisloKurzu-1])
    pole=[]
        pole.push(jenomKurzy[poleMen[cisloKurzu-1]])
    }
    
     
    
    
    setPoleKurzu(pole)
    

   

    }
 
    useEffect( ()=>{
        console.log("Vypis 999");
        
        const vyberbanku = document.querySelector(".jednaBanka")
        console.log(vyberbanku.value);
        getKurzy(vyberbanku.value,"VŠE",0) 
        console.log(poleKurzu); 
        
    },[] )
  
   const Vybraljsi = ()=>{

    const kalendar1 = document.querySelector("#vybDatum")
        console.log("Datum v kalendari="+kalendar1.value);

    console.log("Ted si vybral:");
    const vyberbanku = document.querySelector(".jednaBanka")
        console.log(vyberbanku.value);

    const vybJedenKurz=document.querySelector("#vybJedenKurz")
    console.log("Zvolený jeden kurz="+vybJedenKurz.value)


    let pozadovanyKurz=""
    if (vybJedenKurz.value-1<0) { pozadovanyKurz="VŠE"
    }else{
       pozadovanyKurz= poleMen[vybJedenKurz.value-1]

    }
     console.log("pozadovany kurz="+pozadovanyKurz);
    
        getKurzy(vyberbanku.value,pozadovanyKurz,vybJedenKurz.value)
   }


   const FpoNastaveniDatumu = (x)=>{


    const btn=document.getElementById("btnZobraz")
    if (x==1) {
        btn.style.background="lightyellow" 
    } else {
        btn.style.background="bisque"
    }
    
    // console.log(btn);
   }

   const Vybraljsi1 = ()=>{

    const kalendar1 = document.querySelector("#vybDatum")
        console.log("Datum v kalendari="+kalendar1.value);

    console.log("Ted si vybral:");
    const vyberbanku = document.querySelector(".jednaBanka")
        console.log(vyberbanku.value);
        const pozadovanyKurz="VŠE"
        getKurzy(vyberbanku.value,pozadovanyKurz,0)
   }
   
 
     
   return  ( <div> 
    
            <h1>Kurzovní Lístek </h1>
            <div className="vyberbanku">
            <label htmlFor="vybbanku">Vyber banku:</label>
            <select name="Banky" id="vybbanku" className="jednaBanka" defaultValue={2} onChange={Vybraljsi1} >
                <option value="1">ČSOB</option>
                <option value="2">Česká spořitelna</option>
                <option value="3">Union banka</option>
                <option value="4">Komerční banka</option>
                <option value="5">MONETA</option>
                <option value="6">Česká národní banka</option>
                <option value="8">Živnostenská banka</option>
                <option value="9">eBanka</option>
                <option value="10">UniCredit Bank</option>
                <option value="16">Raiffeisenbank</option>
            </select>

            <label htmlFor="jedenKurz" className="JedenKurzLabel">Vyber Kurz:</label>
            <select name="Kurzy na vyber" id="vybJedenKurz" onChange={()=>FpoNastaveniDatumu(1)}>
                {console.log("vypis pole men do select")}
                {console.log(poleMen)}
                <option value="0">VŠE</option>

                {   
                    poleMen.map((element,index) =>{
                        return <option value={index+1} key={index+1}>{element}</option>
                             {console.log(element,index)}
                        
                       
                    })
                }


            </select>

            {/* {console.log("datum = " + datum)} */}
            <label htmlFor="vybDatum" className="zvolDatum">Zvol datum:</label>
            <input type="date" name="datum" id="vybDatum" onChange={()=>FpoNastaveniDatumu(1)}  className="jednaBanka" />
            </div>

            <div className="zobraz">
                <button type="button" id="btnZobraz" className="zobrazKurz" onClick={Vybraljsi}>Zobraz kurz</button>
            </div>
           
            
               
             <div className="Cele">
            {     
                poleKurzu.map( (jedenKurz) => {
                     
                    const {dev_nakup,dev_prodej,dev_stred,jednotka,nazev,url,val_nakup,val_prodej,val_stred} = jedenKurz  
     
                    return <div className="KurzListek" key={nazev}>
                        {console.log("Vypis 4")}
                        <h2>{nazev}</h2>
                        <h5>{"Jednotka "+jednotka}</h5>
                        
                        <div className="devizyValuty">
                        <div className="Devizy">
                            <h3>Devizy:</h3>
                            <p>{"nákup "+dev_nakup}</p>
                            <p>{"prodej "+dev_prodej}</p>
                            <p>{"střed "+dev_stred}</p>
                        </div>

                        <div className="Valuty">
                            <h3>Valuty:</h3>
                            <p>{"nákup "+val_nakup}</p>
                            <p>{"prodej "+val_prodej}</p>
                            <p>{"střed "+val_stred}</p>
                        </div>
                        </div>
                       



                    </div>
                } )
            }

            

            


            </div>
            <h1>Konec Výpisu</h1>
        </div>
    )
}

export default KurzListek;