
import KurzListek from "./components/KurzListek"

import { useEffect,useState } from "react";



const App = () => {

  // const [poleKurzu,setPoleKurzu]=useState([])

  const url="https://api.kanye.rest/"

  const [citat,setCitat]=useState("")
  


                              // useEffect( ()=> {
                              //   getQuote()
                                
                              // },[] )


                              // const getQuote = async () => {
                              //   const response =await  fetch(url)
                              //   // console.log(response)

                              //   const data= await response.json()
                              //   // console.log()
                              //   // console.log(data);
                                
                              //   setCitat(data["quote"])
                              //   // getKurzy()
                              // }

 
//   const urlkurzy= "https://data.kurzy.cz/json/meny/b[2].json"

//   const getKurzy = async () => {
//     const odpoved =await  fetch(urlkurzy)
//     // console.log(odpoved)

//     const dataKurzy= await odpoved.json()


//     // console.log()
//     // console.log(dataKurzy["kurzy"]["USD"].dev_stred);
//     // console.log(dataKurzy["kurzy"]["USD"].dev_nakup);
//     // console.log(dataKurzy["kurzy"]["USD"].dev_prodej);

//     // console.log(dataKurzy["kurzy"]["USD"].val_stred);
//     // console.log(dataKurzy["kurzy"]["USD"].val_nakup);
//     // console.log(dataKurzy["kurzy"]["USD"].val_prodej);


//     console.log(dataKurzy["kurzy"]["USD"]);
//     const jenomKurzy = (dataKurzy["kurzy"]);

//     // const vysledek = jenomKurzy.map( (jedenUkol) => {
//     //   return jedenUkol.
//     //   })
//     // console.log(jenomKurzy)

//     const kurzUSD=jenomKurzy["USD"]
//     // console.log(kurzUSD);

//   //  const vysledek= kurzUSD.map((element) => {
//   //    return element.dev_stred
      
//   //   })
//   //   console.log(vysledek);


  

// let poleMen = ["AUD","BGN","CAD","CHF","DKK","EUR","GBP","HKD","HUF","JPY","NOK","NZD","PLN","RON","SEK","TND","TRY","USD","ZAR"]
// // console.log(poleMen)

// // console.log(jenomKurzy[poleMen[17]]);
// let poleKurzu=[]
// for (let i=0;i<poleMen.length;i++) {
  
//   const jedenKurz=(jenomKurzy[poleMen[i]])
//   //  console.log(jedenKurz);
//    poleKurzu.push(jedenKurz)
// }

// console.log(poleKurzu)
// setPoleKurzu(poleKurzu)
// // export default poleKurzu
  
//     // setCitat(data["quote"])
    
//   }

  

//   // Základní parametry
  
//   // b - číslo banky. 1 ČSOB,2 Česká spořitelna, 6 ČNB
//   // den - datum pro platný kurzovní lístek
//   // cb - callback pro přímé použití na webu.
//   // .js, json - typ text/javascript nebo application/json 





// const vypisMen = () => {
  
//   console.log("Vypisuji kurzovní lístek")
//   console.log(poleKurzu)
  
// }


  
  
  return (<div>

    
    {/* <h1>Citáty</h1>
    <button onClick={getQuote}>Další citát</button>
    <h2>{citat}</h2> */}

    <br /><br />
   


    <KurzListek/>
    
  </div>
    
  )
}

export default App;